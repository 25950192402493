<template>
  <div>
    <van-nav-bar left-arrow title="待办" fixed @click-left="$router.push({ name: 'Home' })" />

    <div class="content">
      <van-search placeholder="主题" @search="onSearch" v-model="value" shape="round" show-action @cancel="onCancel" />

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-panel
          :title="'【' + item.woName + '】'"
          :desc="item.createTime"
          @click="choseList(item)"
          v-for="(item, index) in list"
          :key="index"
          :status="item.priority | priorityFil"
        >
          <div class="info">{{ item.id + item.businessInfo + '发起人：' + item.startUser }}</div>
        </van-panel>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
      value: '',
      where: []
    };
  },

  methods: {
    onLoad() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/task/toDoList/' + sessionStorage.getItem('userName'),
        data: {
          where: this.where,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          orderBy: 'createTime desc'
        }
      }).then(res => {
        this.list = this.list.concat(res.data.rows);
        this.pageNum++;
        this.pageSize = this.pageSize;
        this.loading = false;
      });
    },
    onSearch() {
      this.where = [{ field: 'businessInfo', opt: 'like', value: this.value, assemble: 'and' }];
      this.list = [];
      this.onLoad();
    },
    onCancel() {
      this.value = '';
      this.pageNum = 0;
      this.pageSize = 10;
      this.where = [{ field: 'businessInfo', opt: 'like', value: '', assemble: 'and' }];
      this.list = [];
      this.onLoad();
    },
    choseList(item) {
      this.$router.push({
        name: 'todoDetail',
        query: item
      });
    }
  },
  filters: {
    priorityFil(val) {
      const colorMap = {
        50: ['green', '一般'],
        100: ['orange', '紧急'],
        150: ['red', '特急']
      };

      let color = colorMap[val] || ['green', '一般'];

      return color[1];
      // return '<font color = ' + color[0] + '>' + color[1] + '</font>'
    }
  }
};
</script>

<style scoped>
.info {
  padding-left: 15px;
  padding-bottom: 10px;
  font-size: 13px;
  color: #454546;
}

.content {
  margin-top: 40px;
}
.van-panel__header-value {
  color: green;
}

.van-cell:not(:last-child)::after {
  border-bottom: none;
}

.van-cell__title {
  color: #38acfe;
}
</style>
