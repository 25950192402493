<template>
  <div>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="审批历史" name="1">
        <van-steps direction="vertical" :active="historyData.filter(item => item.startTime).length">
          <van-step>
            <span>审批开始</span>
          </van-step>
          <van-step v-for="(item, index) in historyData" :key="index">
            <div class="his-container">
              <div>
                <span class="action">{{ item.approvalStatus }}</span> <span class="people">{{ item.assignee }}</span>
                <span class="time">{{ item.endTime }}</span>
              </div>
              <div style="margin-top: 10px" v-show="item.comments">
                <span class="opinion">审批意见：{{ item.comments }}</span>
              </div>
            </div>
          </van-step>
          <van-step>
            <span>审批结束</span>
          </van-step>
        </van-steps>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
export default {
  name: 'historyPanel',
  props: {
    pid: '',// 实例ID
    activeName: {// 默认展开的面板
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      historyData: []
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/task/trace/list/' + this.pid,
        data: {
          where: [],
          pageNum: 0,
          pageSize: 10,
          orderBy: 'id DESC'
        }
      }).then(res => {
        console.log('res', res.data);
        this.historyData = res.data.rows;
      });
    }
  }
};
</script>

<style scoped lang="less">
.his-container {
  background-color: rgba(238, 238, 238, 0.89);
  padding: 10px;
  .action {
    display: block;
    float: left;
    width: 18%;
  }
  .people {
    margin-left: 10px;
    color: #323233;
  }
  .opinion {
    margin-left: 58px;
    color: rgba(93, 93, 94, 0.89);
  }
  .time {
    float: right;
    color: #969799;
  }
}
</style>
