<template>
  <div>
    <van-nav-bar left-arrow title="差旅费报销申请" fixed @click-left="$router.push({ name: 'todoList' })" />

    <div class="content">
      <van-tabs v-model="active">
        <van-tab title="申请信息">
          <van-cell-group>
            <van-cell title="单据编号" :value="detail.orderCode" />
            <van-cell title="单据类型" :value="detail.orderType" />
            <van-cell title="报销申请日期" :value="detail.createTime" />
            <van-cell title="报销人" :value="detail.reimburseBy" />
            <van-cell title="部门" :value="detail.reimburseDept" />
            <van-cell title="费用类型" :value="detail.feeType | toSysDict('feeType')" />
            <van-cell title="费用金额(元)" :value="detail.feeAmount" />
            <van-cell title="状态" :value="detail.status | toSysDict('orderStatus')" />
            <van-cell title="备注" :value="detail.remark" />
          </van-cell-group>
        </van-tab>
        <van-tab title="审批历史">
          <history-panel :pid="$route.query.pid" activeName="1"></history-panel>
        </van-tab>
      </van-tabs>
      <approval :detail="$route.query" :businessInfo="detail" taskType="waitfor" :customSubmitBtnName="customSubmitBtnName"></approval>
    </div>
  </div>
</template>

<script>
import approval from '@/components/approval/approval';
import historyPanel from '@/components/approval/historyPanel';
export default {
  name: 'detail',
  components: { approval, historyPanel },
  comments: {
    approval
  },
  data() {
    return {
      active: 0,
      customSubmitBtnName: ['同意', '不同意'],
      businessKey: '',
      detail: {},
      process: false,
      approval: false,
      taskInstanceId: ''
    };
  },
  methods: {
    getDetail() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/sysorder/view',
        data: {
          entity: {
            id: this.businessKey
          }
        }
      }).then(res => {
        this.detail = res.data.entity;
        console.log('res', res.data.entity);
      });
    }
  },
  mounted() {
    console.log('sssssssssssss');
    this.businessKey = this.$route.query.businessKey;
    this.getDetail();
  }
};
</script>

<style scoped>
.content {
  margin-top: 47px;
}
</style>
